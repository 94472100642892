/* Pulse Animation */

$speeds: (
  "slow": 3s,
  "medium": 1s,
  "fast": 0.5s,
);

@each $speed, $time in $speeds {
  .pulse-#{$speed} {
    animation: pulse $time linear 0s infinite;
  }
  .blink-#{$speed} {
    animation: _blink $time linear 0s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes _blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
