@import "src/styles/colors";
@import "src/styles/mixins";

.service-card {
  transition: 0.3s;
  min-width: 100px;
  max-width: 800px;

  @include themify($themes) {
    background: darken(themed("body-bg"), 4%);
    border: 1px lighten(themed("sidebar-color"), 20%) solid;
  }
}

.service-card[disabled] {
  display: none !important;
}

.service-card:hover {
  box-shadow: 1px 5px 5px #15223214;
  transform: scale(102%);
}

.service-card .provider-icon .div {
  margin: auto;
}

.provider-icon {
  width: 5rem;
  margin-left: 0.5rem;
  @include themify($themes) {
    color: lighten(themed("sidebar-color"), 20%);
  }
  transition: 0.3s;
}

.service-card:hover .aws-color {
  color: hsl(36, 100%, 50%);
}

.service-card:hover .azure-color {
  color: hsl(210, 100%, 50%);
}

.service-card:hover .gcp-color {
  color: hsl(136, 53%, 43%);
}

.service-card:hover .default-color {
  @include themify($themes) {
    color: lighten(themed("sidebar-color"), 20%);
  }
}

.card-title {
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font-size: 1rem;
  font-weight: bold;
}

.flex-container {
  display: flex;
  align-items: center;
}

.card-icon {
  flex: auto;
  margin-right: 1rem;
}

.card-text {
  flex: auto;
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.small-icon {
  font-size: 1.50rem; 
}

.tight-spacing .card-icon {
  margin-right: 0.2rem; 
}

.tight-spacing .card-text {
  margin-left: 0.2rem;
}

.small-text {
  font-size: 0.8rem;
  font-weight: normal;
}
