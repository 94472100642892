@import 'src/styles/colors';
@import 'src/styles/mixins';

.simple-timeline-styles {
  display: flex;
  flex-direction: column;
  align-items: center;
  .timeline-card-content {
    min-height: unset !important;
  }
  .timeline-main-wrapper {
    padding: 0 !important;
    max-height: 30rem;
    &::-webkit-scrollbar {
      display: none;
    }
    div:first-child[role="list"] {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .timeline-item-title, .timeline-item-title.active {
    @include themify($themes) {
      color: themed('service-request-button') !important;
    }
    font-size: 14px;
    background: none !important;
  }
  .card-title {
    font-size: 14px;
    padding-left: 15.5125px;
    @include themify($themes) {
      color: themed('service-request-button') !important;
    }
  }
  .vertical-item-row div:last-child[role="button"]::after {
    @include themify($themes) {
      background: themed('timeline-bar-color') !important;
    }
    
  }
  .card-content-wrapper {
    margin: 1rem 0;
  }
  .card-description{
    padding-top: 0;
    p {
      font-size: 12px;
      line-height: 1.2em;
    }
  } 
  .timeline-item-icon {
    height: 0.9rem;
  }
  .using-icon {
    height: auto;
  }
  .extra-timeline-strip {
    @include themify($themes) {
      background: themed('timeline-bar-color');
    }
    height: 50px;
    width: 4px;
  }
  .msg-input {
    width: 25rem;
  }
  .send-btn {
    @include themify($themes) {
      background: themed('service-request-button') !important;
      color: themed('white-color');
    }
  }
}
.msgErr{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
  text-align: center;
}