@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

.user-select-container {
  display: block;
  position: relative;
  width: 100%;
}

.user-select-group {
  overflow-y: auto;
  max-height: 240px;
  margin-top: 0.5rem !important;
  display: block !important;
  position: absolute !important;
  z-index: 1099 !important;
  width: inherit !important;
  border: 1px solid;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  padding: 0;
}

.user-select-item {
  display: flex;
  align-items: center;
  height: calc(240px / 4);
  background-color: #fff !important;
  text-align: left;
  border-bottom: none !important;
  border-top: 1px dashed !important;
  border-left: none !important;
  border-right: none !important;
  border-color: #d8dbe0 !important;
  padding: 0.75rem 0.5rem !important;
  width: 100%;
}

.user-select-item:hover {
  background-color: #f8f9fa !important;
}

.user-select-item:first-child {
  border-top: none !important;
}

.user-select-group::-webkit-scrollbar {
  display: none;
}

.input-is-fetching {
  @include themify($themes) {
    border-color: themed("warning") !important;
    box-shadow: 0 0 0 hsl(41, 95%, 53%);
    animation: pulsate 2s infinite;
  }
}
@-webkit-keyframes pulsate {
  0% {
    box-shadow: 0 0 0 hsl(41, 95%, 53%);
  }
  50% {
    box-shadow: 0 0 6px hsl(41, 95%, 53%);
  }
  100% {
    box-shadow: 0 0 0 hsl(41, 95%, 53%);
  }
}

.input-loading-container {
  position: relative;
}

.icon-loading-container {
  position: absolute;
  right: 10px;
  top: calc(50% - 7.5px);
  z-index: inherit;
}

.icon-loading-array-container {
  position: absolute;
  right: 42px;
  top: calc(50% - 7.5px);
  z-index: 1100 !important;
}

.icon-loader {
  position: relative;
  height: 15px;
  width: 15px;
  display: inline-block;
  animation: icon-around-loading 5.4s infinite;
}

@keyframes icon-around-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-loader::after,
.icon-loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: hsl(41, 95%, 53%) hsl(41, 95%, 53%) transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.icon-loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.active-user {
  background: #cdf5a3 !important;
  color: #636f83 !important;
}
