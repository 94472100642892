@import 'src/styles/colors';
@import 'src/styles/mixins';

.custom-tooltip {
  border: none;
  background: transparent;
  position: relative;
  display: inline-block;
  opacity: 1;

  .tooltip-container {
    @include themify($themes) {
      box-shadow: 0px 1px 4px themed('box-shadow-color');
      border-color: themed('tooltip-border-color');
      background: themed('sidebar-active-item');
    }
    // visibility
    visibility: hidden;
    opacity: 0;

    // styling
    border-radius: 6px;
    border: 1px solid;
    color: black;
    font-size: 0.875rem;
    transition: opacity 0.3s;
    
    // layouting
    text-align: center;
    padding: 5px 5px;
    width: max-content;
    max-width: 20rem;

    // positioning
    position: absolute;
    z-index: 1100;
    top: 125%;
    left: 50%;
    transform:translate(-50%);
    -webkit-transform:translate(-50%);
    .content-body {
      text-align: left;
      font-size: 0.875rem;
    }
  }

  .tooltip-container::before {
    @include themify($themes) {
      border-color: transparent transparent themed('tooltip-border-color') transparent;
    }
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
  }

  &:hover {
    outline: none;
    .tooltip-container {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tooltip-info-icon {
  height: 15px;
}

.sidebar-tooltip-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .tooltip-container {
    @include themify($themes) {
      box-shadow: 0px 1px 4px themed("box-shadow-color");
      border-color: themed("tooltip-border-color");
      background: themed("sidebar-active-item");
    }
    // visibility
    visibility: hidden;
    opacity: 0;

    // styling
    border-radius: 6px;
    border: 1px solid;
    color: black;
    font-size: 14px;
    transition: opacity 0.3s;

    // layouting
    text-align: center;
    padding: 5px 8px;
    width: max-content;
    max-width: 20rem;

    // positioning
    position: absolute;
    z-index: 1100;
    right: -7px;
    transform: translate(100%);
    -webkit-transform: translate(100%);
  }

  .tooltip-container::before {
    @include themify($themes) {
      border-color: transparent themed("tooltip-border-color") transparent
        transparent;
    }
    content: "";
    position: absolute;
    right: 101%;
    top: 36%;
    margin-bottom: 5px;
    border-width: 5px;
    border-style: solid;
  }

  &:hover {
    .tooltip-container {
      visibility: visible;
      opacity: 1;
    }
    .tip-container {
      display: block;
      opacity: 1;
    }
  }
  .tip-container {
    @include themify($themes) {
      box-shadow: 0px 1px 4px themed("box-shadow-color");
      border-color: themed("tooltip-border-color");
      background: themed("sidebar-active-item");
      width: 250px;
      height: auto;
      max-height: 150px;
    }
    display: none;
    opacity: 0;

    // styling
    border-radius: 5px;
    border: 1px solid;
    color: black;
    font-size: 12px;
    transition: opacity 0.3s;

    // positioning
    position: absolute;
    z-index: 1100;
    &.right {
      left: -133%;
    }
    &.left {
      right: 113%;
    }
    &.bottom {
      top: 125%;
      left: 50%;
      transform: translate(-50%);
      -webkit-transform: translate(-50%);
    }

    .tabletip-container {
      // layouting
      text-align: left;
      padding: 5px 8px;
      max-width: 100%;
      height: auto;
      max-height: 140px;
      white-space: break-spaces;
      overflow-wrap: break-word;
      overflow-y: scroll;
    }
  }

  .tabletip-container::-webkit-scrollbar {
    width: 10px;
  }

  .tabletip-container::-webkit-scrollbar-track {
    background: none;
  }

  .tabletip-container::-webkit-scrollbar-thumb {
    background: #888;
  }

  .tabletip-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .tip-container::after {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
  }
  .tip-container.right::after {
    @include themify($themes) {
      border-color: transparent transparent transparent
        themed("tooltip-border-color");
    }
    margin-top: -10px;
    left: 100%;
    top: 50%;
  }
  .tip-container.bottom::after {
    @include themify($themes) {
      border-color: transparent transparent themed("tooltip-border-color")
        transparent;
    }
    left: 50%;
    bottom: 100%;
  }
  .tip-container.left::after {
    @include themify($themes) {
      border-color: transparent transparent transparent
        themed("tooltip-border-color");
    }
    margin-top: -10px;
    left: 100%;
    top: 50%;
  }
}
