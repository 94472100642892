@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic;
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 200;
  font-style: italic;
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 200;
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: italic;
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf")
      format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: local("Open Sans"),
    url("../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  font-weight: 700;
  src: local("Poppins"),
    url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("Poppins"),
    url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  font-weight: 600;
  src: local("Poppins"),
    url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  font-weight: 400;
  src: local("Poppins"),
    url("../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica-Regular";
  font-weight: 400;
  src: local("Helvetica"),
    url("../assets/fonts/Helvetica/Helvetica.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 200;
  src: local("Helvetica"),
    url("../assets/fonts/Helvetica/helvetica-light-587ebe5a59211.ttf")
      format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 700;
  src: local("Helgvetica"),
    url("../assets/fonts/Helvetica/Helvetica-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Domine";
  font-weight: 400;
  src: local("Domine"),
    url("../assets/fonts/Domine/Domine-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Domine";
  font-weight: 600;
  src: local("Domine"),
    url("../assets/fonts/Domine/Domine-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Domine";
  font-weight: 700;
  src: local("Domine"),
    url("../assets/fonts/Domine/Domine-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSerifDisplay";
  font-style: italic;
  font-weight: 400;
  src: local("DMSerifDisplay"),
    url("../assets/fonts/DMSerifDisplay/DMSerifDisplay-Italic.ttf")
      format("truetype");
}

@font-face {
  font-family: "DMSerifDisplay";
  font-weight: 400;
  src: local("DMSerifDisplay"),
    url("../assets/fonts/DMSerifDisplay/DMSerifDisplay-Regular.ttf")
      format("truetype");
}
body {
  font-family: "Poopins-Regular Open Sans", Verdana, Helvetica, sans-serif !important;
  font-weight: 400;
}

.heading-text {
  font-family: "Poppins", "Domine", "Arial Narrow Bold", sans-serif !important;
}

.alt-heading-text {
  font-family: "DMSerifDisplay", "Arial Narrow Bold", sans-serif !important;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .heading-text;
  color: rgba(80, 80, 80, 0.8);
}

p {
  margin-bottom: 0 !important;
}

$fontThemes: (
  nestle: (
    "font-family": "Helvetica",
    "font-family-regular": "Helvetica-Regular",
    "font-family-bold": "Helvetica-Bold",
    "font-family-medium": "Helvetica-Medium"
  ),
  deloitte: (
    "font-family": "Poppins",
    "font-family-regular": "Poppins-Regular",
    "font-family-bold": "Poppins-Bold",
    "font-family-medium": "Poppins-Medium"
  ),
  protanopia: (
    "font-family": "Poppins",
    "font-family-regular": "Poppins-Regular",
    "font-family-bold": "Poppins-Bold",
    "font-family-medium": "Poppins-Medium"
  ),
  deuteranopia: (
    "font-family": "Poppins",
    "font-family-regular": "Poppins-Regular",
    "font-family-bold": "Poppins-Bold",
    "font-family-medium": "Poppins-Medium"
  ),
  tritanopia: (
    "font-family": "Poppins",
    "font-family-regular": "Poppins-Regular",
    "font-family-bold": "Poppins-Bold",
    "font-family-medium": "Poppins-Medium"
  ),
  achromatopsia: (
    "font-family": "Poppins",
    "font-family-regular": "Poppins-Regular",
    "font-family-bold": "Poppins-Bold",
    "font-family-medium": "Poppins-Medium"
  )
);
