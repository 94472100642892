.srvreq-heading {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
  }
  
  .srvreq-sub-heading {
    font-size: 1rem;
    text-align: left;
    color: #6c757d;
  }
  
  .srvreq-field-label {
    margin-bottom: 0.5rem;
  }
  
  .srvreq-demo-card {
    border: 1px solid #ced4da;
    background-color: #f1f3f5;
  }
  
  .srvreq-demo-card-header {
    background-color: #e9ecef;
    font-weight: bold;
  }
  
  .srvreq-custom-radio-group {
    display: flex;
    flex-direction: column;
  }
  
  .srvreq-custom-radio-group .srvreq-radio-option {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
  }
  
  .srvreq-custom-radio-group .srvreq-radio-option input[type="radio"] {
    margin-right: 1rem;
    vertical-align: middle;
    width: 16px;
    height: 16px;
  }
  
  .srvreq-custom-radio-group .srvreq-radio-option label {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 8px;
  }
  
  .srvreq-invalid-feedback {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .srvreq-custom-radio-group
    .srvreq-radio-option
    input[type="radio"]:focus
    + label {
    outline: 2px solid #007bff;
    outline-offset: 2px;
  }
  
  @media (max-width: 768px) {
    .srvreq-heading {
      font-size: 1.3rem;
    }
  
    .srvreq-sub-heading {
      font-size: 0.9rem;
    }
  }
  