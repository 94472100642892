@import 'src/styles/mixins';

.awsaccess-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #367B3A;
  }
}

.awsuser-list {
  columns: 2;
}

.awsuser-list>div {
  flex: 1 1 200px;
  /* Adjust basis to control when wrapping occurs */
}


.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: rgb(7, 211, 123);
  border-color: #007bff;
}


.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #007bff; 
  border-color: #007bff; 
}

.custom-checkbox label {
  margin: 0;
  padding: 0; 
  line-height: 16px; 
}
