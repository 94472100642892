.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #0fc39a;
  fill: none;
  animation: stroke 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 30px;
  box-shadow: inset 0px 0px 0px #0fc39a;
  animation: fill 0.4s ease-in-out 0.8s forwards;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) 1.2s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 180px #0fc39a;
  }
}
