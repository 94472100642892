@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

$sidebar-open-padding: 200px;
$sidebar-closed-padding: 86px;
$app-header-height: 12px;
$app-footer-height: 50px;

.app-body {
  @include themify($themes) {
    background-color: themed("body-bg");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  padding-top: $app-header-height !important;
  padding-bottom: $app-footer-height !important;
  height: 100% !important;
  width: 100% !important;
}
.app-body--unauthenticated {
  padding: 0 !important;
  margin: 0 !important;
}
.app-body--sidebar-closed {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .route-wrapper > div {
    @media screen and (min-width: 601px) {
      padding-left: $sidebar-closed-padding;
      padding-right: 15px;
    }
    @include themify($themes) {
      background-color: themed("body-bg");
    }
  }
}
.app-body--sidebar-open {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .route-wrapper > div {
    padding-left: $sidebar-open-padding;
    padding-right: 15px;
    @include themify($themes) {
      background-color: themed("body-bg");
    }
  }
}

.app-header {
  transition: all ease 0.3s;
}

.app-header--sidebar-closed {
  padding-left: $sidebar-closed-padding !important;
}
.app-header--sidebar-open {
  padding-left: $sidebar-open-padding !important;
}

.app-footer {
  position: fixed;
  bottom: 0;
}
.app-footer--sidebar-open {
  padding-left: $sidebar-open-padding !important;
}
.app-footer--sidebar-closed {
  padding-left: $sidebar-closed-padding !important;
}

.app {
  height: 100vh;
}

.c-sidebar-header {
  display: none;
}
.c-sidebar-minimized {
  width: 72px !important;
}
table {
  @include themify($themes) {
    border-color: themed("table-border-color");
  }
  border-spacing: 0;
  border: 1px solid;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  @include themify($themes) {
    border-bottom-color: themed("table-border-color");
    border-right-color: themed("table-border-color");
  }
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid;
  border-right: 1px solid;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  @include themify($themes) {
    background-color: themed("table-bg-color");
  }
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  @include themify($themes) {
    border-bottom-color: themed("table-border-color-secondary");
  }
  border-bottom: 5px solid;
}
table th.sort-desc::before {
  @include themify($themes) {
    border-top-color: themed("table-border-color-secondary");
  }
  border-top: 5px solid;
}

.modal-body {
  white-space: pre-line;
  .card {
    @media screen and (max-width: 600px) {
      padding: 0 0.6rem;
    }
  }
}

.wide-modal-dialog {
  .modal-dialog {
    width: 70%;
    max-width: 120em;
  }
}

@media only screen and (min-width: 120em) {
  .wide-modal-dialog {
    .modal-dialog {
      width: 45%;
      max-width: 120em;
    }
  }
}
.caption-text-color {
  @include themify($themes) {
    color: themed("ticket-summary-text");
  }
  width: 15%;
}
code {
  @include themify($themes) {
    color: themed("code-&-link-color");
    background-color: themed("background-color");
  }
  border-radius: 3px;
  font-family: courier, monospace;
  padding: 0 3px;
}

a {
  @include themify($themes) {
    color: themed("code-&-link-color");
  }
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  @include themify($themes) {
    color: themed("default-link-hover-color");
  }
  text-decoration: none;
}

.highlight:hover {
  @include themify($themes) {
    color: themed("default-link-hover-color");
  }
}

.separator {
  @include themify($themes) {
    background-color: themed("seperator-color");
  }
  width: 40px;
  height: 5px;
  display: block;
  margin: 5px 0px 0px;
}

#okta-sign-in {
  .auth-org-logo {
    max-height: 100px;
  }
  .o-form-head {
    margin-bottom: 50px;
  }
  .focused-input {
    @include themify($themes) {
      box-shadow: 0 0 8px themed("okta-sign-in-color");
    }
  }
  .link.help:focus {
    @include themify($themes) {
      box-shadow: 0 0 8px themed("okta-sign-in-color");
    }
  }
}

#okta-sign-in.auth-container {
  .button-primary {
    @include themify($themes) {
      background: themed("okta-sign-in-color");
      border: themed("okta-sign-in-color");
    }
    font-size: 15px;
  }
  .button-primary:hover {
    @include themify($themes) {
      background: themed("okta-sign-in-hover");
      border: themed("okta-sign-in-hover");
    }
  }
  .okta-form-input-field.focused-input {
    @include themify($themes) {
      border-color: themed("okta-form-input");
    }
  }
}

.app-login {
  background-image: url(../assets/img/brand/background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.borderless {
  border: 0 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
}

.transparent {
  opacity: 0 !important;
}

.hw-100 {
  @extend .h-100;
  @extend .w-100;
}

.card-height-mr {
  @media screen and (max-width: 600px) {
    height: 90%;
  }
  height: 100%;
}

.acc-dd-height {
  max-height: 17rem;
}

.mp-0 {
  @extend .m-0;
  @extend .p-0;
}

.text-strikethrough {
  text-decoration: line-through;
}

.c-sidebar {
  background-color: black;
  color: var(--secondary);
}

.shine {
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -50%;
    left: 0;
    display: block;
    content: "";
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100%;
  }
}

.cross-icon {
  z-index: 5;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.gradient-shine {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    );
  }
}
.glossy {
  @extend .gradient-shine;
  border: 1px outset rgba(255, 255, 255, 0.8);
}

.c-header {
  border-bottom: 2px solid rgba(80, 80, 80, 0.4);
}
.card {
  @include themify($themes) {
    box-shadow: 0px 1px 4px themed("box-shadow-color");
  }
  border: 0;
}

.card-header {
  border-bottom: 0 !important;
}

.w-fit-content {
  width: fit-content;
}

hr {
  border: 1px solid rgba(80, 80, 80, 0.4);
}
.route-wrapper {
  position: relative;
  & > div {
    position: absolute;
  }
}

.icon-Style {
  width: 22px;
  height: 22px;
  vertical-align: text-bottom;
  margin-right: 12px;
}

.icon-Style-small {
  width: 17px;
  height: 17px;
  margin-right: 12px;
}

.larger-Text {
  font-size: 30px;
}
.large-Text {
  font-size: 24px;
}
.medium-text {
  font-size: 14px;
}

.small-Text {
  @include themify($themes) {
    color: themed("primary-text-color");
  }
  font-size: 11px;
}
.small-text-height {
  height: 2rem;
}
.padding-remove {
  padding: 0px !important;
}

.box-padding {
  padding-left: 20px;
}

.dotted-border:not(:last-child) {
  border-right: 1px dotted rgb(189, 182, 182);
}
.border-bottom {
  border-bottom: 1px dotted rgb(189, 182, 182);
}
.dotted-border-responsive:not(:last-child) {
  @media screen and (min-width: 768px) {
    border-right: 1px dotted rgb(189, 182, 182);
  }
  @media screen and (max-width: 768px) {
    border-bottom: 1px dotted rgb(189, 182, 182);
  }
}

.borderRightBottomRad {
  border-bottom-right-radius: 5px;
}

.adjustWelcomePadding {
  padding-left: 16px;
}

.banner {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.banner-title {
  @include themify($fontThemes) {
    font-family: themed("font-family-regular") !important;
  }
  font-size: 18px;
}

.smallScreenSpace {
  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.title {
  @include themify($themes) {
    background-color: themed("welcome-banner-color");
    color: themed("welcome-banner-text") !important;
  }
  position: relative;
  padding-top: 12px;
  // color: white !important;
  font-size: 12px;
  background: 0% 0% no-repeat padding-box;
}
.title:before {
  @include themify($themes) {
    background-color: themed("welcome-banner-color");
  }
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: 0% 0% no-repeat padding-box;
  transform-origin: bottom left;
  -ms-transform: skew(21deg, 0deg);
  -webkit-transform: skew(21deg, 0deg);
  transform: skew(21deg, 0deg);
  border-bottom-right-radius: 5px;
}
.z-index {
  z-index: 4;
}

.dashboardULStyles {
  font-size: 13px;
  line-height: 22px;
}

.background-white {
  background-color: white;
  max-width: 100%;
}

.iconDimensions {
  width: 34px;
  height: 34px;
}
.dashboard-heading {
  @include themify($themes) {
    color: themed("sidebar-color");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font: normal normal bold 18px;
}
.securityComplianceBoxShadow {
  @include themify($themes) {
    box-shadow: 0px 1px 4px themed("box-shadow-color");
  }
  border: 0;
}
.card-style {
  @include themify($themes) {
    background-color: themed("white-color");
    box-shadow: 0px 1px 4px themed("box-shadow-color");
  }
  background: 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  margin: 0px;
}

.remove-border {
  border-top-left-radius: 0px !important;
}

.text-alignment {
  padding-left: 20px;
}
.right-style {
  padding-right: 35px;
}

.awaitingIncidentText-style {
  margin-left: 14px;
  font-weight: 500;
}
.awaitingInfo-style {
  margin-left: 35px;
}

.center-content {
  width: fit-content;
  margin: 0 auto;
}

.w-fit-content {
  width: fit-content;
}

.chartContainer {
  margin: 0 auto;
  max-width: 100%;
  height: 173px;
  position: relative;
  @media screen and (min-width: 768px) {
    top: -2.1rem;
  }
}
.chartInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 19%;
  right: 20%;
  bottom: 20%;
  left: 20%;
  border-radius: 50%;
  padding: 1.25em 0;
  line-height: 120%;
  overflow: hidden;
}
.chartStatus {
  font-size: medium;
}
.chartValue {
  font-size: 9px;
  line-height: 10px;
}
.chartTarget,
.chartDaysLabel {
  font-size: 0.75em;
}
.chartDaysRemaining {
  font-size: 16px;
}

.bold-text {
  @include themify($fontThemes) {
    font-family: themed("font-family-regular"), sans-serif;
  }
  font-weight: bold;
}

.chart {
  text-align: center;
  color: black;
}
.chart-heigth {
  @media screen and (min-width: 768px) {
    max-height: 7rem;
  }
}

.center-box {
  margin: 0 auto;
  width: 85%;
}

.heading-style {
  @include themify($themes) {
    color: themed("key-contacts-text");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family-regular") !important;
  }
  font-size: 16px;
}
.list-padding {
  padding-left: 3.5rem !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select,
.custom-control-input:checked ~ .custom-control-label::before {
  background: 0% 0% no-repeat padding-box; /* this bg image SVG is just a white circle, you can replace it with any valid SVG code */
}

.custom-switch .custom-control-label::after {
  background-color: white;
}
.space-ticket {
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  @media screen and (max-width: 768px) {
    padding-left: 5%;
  }
}

.align-item-horizontal {
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: left;
    align-items: center;
  }
}

.legend-design {
  width: 10px;
  height: 20px;
  opacity: 1;
}

.openticket-color-border {
  @include themify($themes) {
    border-color: themed("bar-color-4");
  }
  border: 2px solid;
}

.resolved-color-border {
  @include themify($themes) {
    border-color: themed("resolved-border");
  }
  border: 2px solid;
}

.resolved-engineer-color-border {
  @include themify($themes) {
    border-color: themed("bar-color-8");
  }
  border: 2px solid;
}
.incident-color {
  @include themify($themes) {
    background-color: themed("bar-color-4");
  }
  background: 0% 0% no-repeat padding-box;
}

.resolved-color {
  @include themify($themes) {
    background-color: themed("resolved-color");
  }
  background: 0% 0% no-repeat padding-box;
}

.paragraph-lineheight {
  line-height: 11px;
}

// sidebar styles

.sideHeaderIcon--sidebar-open {
  height: 50px;
}

.deloitteIconText {
  @include themify($themes) {
    color: themed("sidebar-active-item");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family-regular") !important;
  }
  font-size: 14px;
}

.byDeloitteText {
  @include themify($themes) {
    color: themed("sidebar-active-item");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family-regular") !important;
  }
  font-size: 11px;
}

.sideBarIconstyles {
  width: 40px;
  height: 36px;
}

.checkActiveItem {
  p svg g line,
  p svg g {
    @include themify($themes) {
      stroke: themed("sidebar-color");
    }
    opacity: 1;
    transition: all ease 0.3s;
  }
}
.sidebarProfileStyles {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.sideBarButtonOpen,
.sideBarButtonClose {
  @include themify($themes) {
    background: themed("sidebar-color");
  }
  svg .b {
    fill: none !important;
  }
  width: 30px;
  height: 30px;
  padding: 2px;
  transform: translate(-19px, 50px);
  margin-top: -1.65rem;
  transition: all ease 0.3s;
}

.sideBarButtonClose {
  @include themify($themes) {
    background: themed("sidebar-color");
  }
}

.headerStylesForHide {
  height: 0px;
  min-height: unset;
}

.titleStyles {
  @include themify($themes) {
    color: themed("title-text");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  padding-left: 20px;
  font-weight: 200;
  font-size: 20px;
}
.titleStyles-mbl {
  @include themify($themes) {
    color: themed("title-text");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family-medium");
  }
  padding-left: 7.5px;
  font-weight: 200;
  font-size: 1.2rem;
  margin-top: 3.5rem;
}

.sidebarImageStyles {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px;
}

.adjustProfilePosition {
  position: absolute;
  bottom: 5px;
}

.username {
  @include themify($themes) {
    color: themed("sidebar-active-item");
  }
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font-size: 12px;
}

.profileImageAndTextHr {
  position: absolute;
  bottom: 40px;
  width: 92%;
  left: 0px;
  border: 0.2px dashed white;
}

.sidebarHeightAndColor {
  @include themify($themes) {
    background-color: themed("sidebar-color");
  }
  height: 100vh;
}
.dashedBorder:not(:first-child) {
  border-top: 1px dashed lightgray;
}

.sidebar-nav-item,
.collapseNavItemButton {
  p svg path {
    fill: white;
    stroke: white;
  }
  p svg g line,
  p svg g {
    stroke: white;
  }
}

.sidebar-nav-item-padding {
  padding: 5px;
}
.checkActiveItem,
.check-active-item-collapse {
  @include themify($themes) {
    background: linear-gradient(
      to right,
      themed("bar-color-1") 2%,
      themed("sidebar-active-item") 1%
    );
  }
  width: 99% !important;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
  height: auto;
  .sidebar-nav-item-name {
    @include themify($themes) {
      color: themed("sidebar-color");
    }
    @include themify($fontThemes) {
      font-family: themed("font-family");
    }
    font-weight: 700;
  }

  p svg path {
    @include themify($themes) {
      fill: themed("sidebar-color");
      stroke: themed("sidebar-color");
    }
    opacity: 1;
    transition: all ease 0.3s;
  }
}

.check-active-item-collapse {
  padding-bottom: 15px;
  padding-top: 15px;
}

.sub-navitem {
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 2rem;
  @include themify($themes) {
    color: themed("white-color");
  }
  &:hover {
    @include themify($themes) {
      color: themed("white-color");
    }
  }
}

.check-active-sub-navitem {
  @include themify($themes) {
    color: themed("sidebar-active-item");
  }
  &:hover {
    @include themify($themes) {
      color: themed("sidebar-active-item");
    }
  }
}

.sidebuttonsvgarrow {
  transform: rotate(180deg);
}

.sidebar-nav-item-name {
  @include themify($fontThemes) {
    font-family: themed("font-family-regular");
  }
  font-size: 12px;
  min-width: 120px;
  color: white;
  font-weight: 400;
}

.iconWidth {
  width: 50px;
}
.collapseNavItemButton {
  color: white;
  width: 98% !important;
  box-shadow: unset !important;
}

.collapseNavItemButton:focus {
  color: black !important;
}

.collapseNavItemButton:hover {
  color: black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.inverted {
  transform: rotate(180deg);
}

.pagination {
  display: flex;
  list-style: none;
  margin: 0 2rem;
}

.drawer-footer {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 1rem 0rem;
}

.records-dd {
  border: solid 0.05rem;
  height: 2rem;
  width: 17%;
}

.dotted-divider {
  width: 100%;
  border-bottom: 1px dotted;
}

.dropdown-menu {
  max-height: 20rem;
  overflow-y: auto;
  min-width: 100%;
}

.quick-link-proceed {
  width: 60px;
}

.align-center {
  text-align: center;
}

.text-secondary {
  @include themify($themes) {
    color: themed("sidebar-color") !important;
  }
}

.color-blue {
  @include themify($themes) {
    color: themed("sidebar-color");
  }
}

.text-secondary-incident {
  @include themify($themes) {
    color: themed("incident-secondary-text");
  }
}
.text-primary {
  @include themify($themes) {
    color: themed("primary-text-color") !important;
  }
  min-width: 50px;
}

.font-class {
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font: normal normal normal 20px/24px;
}

.select-account-box {
  @include themify($themes) {
    background-color: themed("sidebar-color");
    color: themed("sidebar-active-item");
  }
  background: 0% 0% no-repeat;
  opacity: 1;
  padding: 0.4rem 1.4rem;
  opacity: 1;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.adjustDropdownHeight {
  @include themify($fontThemes) {
    font-family: themed("font-family") !important;
  }
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  font: 11px !important;
}
.padding-box {
  @include themify($fontThemes) {
    font-family: themed("font-family") !important;
  }
  font: normal normal normal 12px !important;
  padding: 0.4rem 1.3rem;
  letter-spacing: 0px;
}
.AccountDropdown-Radius {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.header-dropdown-width {
  width: 17rem;
}

.pageBoxShadow {
  @include themify($themes) {
    box-shadow: 0px 1px 4px themed("box-shadow-color");
  }
}
.dropdown-toggle::after {
  display: none;
}

textarea.form-control {
  height: 6rem;
}

textarea.form-control::placeholder {
  font-style: italic;
}

input.form-control::placeholder {
  font-style: italic;
}

.dropdownStyle {
  min-width: 0rem !important;
  font-size: 8px;
  transform: translate(13px, 35px) !important;
}
.dropdownItem {
  @include themify($themes) {
    color: themed("drawer-tabs") !important;
  }
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font-weight: normal;
  padding: 0.5rem 0.5rem;
  font-size: 0.8rem;
}

.sidebar-icon-size {
  height: 1em;
  width: 1em;
}

.sidebar-border-bottom {
  @include themify($themes) {
    border-bottom-color: themed("code-&-link-color");
  }
  border-bottom: 1px dashed;
  &:hover {
    @include themify($themes) {
      border-bottom-color: themed("default-link-hover-color");
    }
  }
}

.sidebar-nav-item-container {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
}

.sidebar-nav {
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
}

.w-95 {
  width: 95% !important;
}

.w-98 {
  width: 98% !important;
}

.w-20 {
  width: 20% !important;
}

.sidebar-nav::-webkit-scrollbar {
  display: none;
}

.rowRightMarginUnset {
  margin-right: 0;
}
.textBox-style {
  @include themify($themes) {
    box-shadow: 0px 1px 4px themed("box-shadow-color");
  }
}
.iconDropdownStyle {
  width: 15px;
  margin-left: 0.4rem;
}
.clickButton {
  background-color: white;
  color: black;
}

.circular-legend {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.im-firstwidget-header {
  min-height: 42px;
  padding-bottom: 10px;
}

.height-auto {
  height: auto !important;
}

.sidebar-close-padding {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
}

.header-btn-style {
  @include themify($themes) {
    background-color: themed("white-color");
    box-shadow: 0px 1px 4px themed("box-shadow-color");
  }
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 0.3em;
}

.no-data-color,
.went-wrong-color {
  @include themify($themes) {
    color: themed("sidebar-color");
  }
}

.no-data-img,
.went-wrong-img {
  width: 200px;
}

.ticket-sla-top-margin {
  margin-top: -4px !important;
  .tooltip-container {
    transform: translate(-45%) !important ;
    -webkit-transform: translate(-45%) !important ;
    &::before {
      left: 45% !important ;
    }
  }
}

.acc-checkbox {
  [type="checkbox"] {
    opacity: 0;
  }

  [type="checkbox"] + label {
    @include themify($themes) {
      color: themed("drawer-tabs");
    }
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    line-height: 25px;
    margin-top: 0.5rem;
  }

  [type="checkbox"] + label::before {
    @include themify($themes) {
      border-color: themed("sidebar-color");
      background: themed("white-color");
    }
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 23px;
    height: 22px;
    border: 1.4px solid;
    border-radius: 4px;
  }

  [type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 7px;
    width: 23px;
    height: 22px;
    background-image: url("../assets/img/tick.png");
    background-size: 14px;
    background-repeat: no-repeat;
    transform: scale(1);
  }

  [type="checkbox"]:disabled + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    color: gray;
    line-height: 25px;
  }

  [type="checkbox"]:disabled + label::before {
    @include themify($themes) {
      background: themed("checkbox-disabled-color");
    }
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 23px;
    height: 22px;
    border: 1.4px solid gray;
    border-radius: 4px;
  }
}

.select-all-checkbox {
  [type="checkbox"] {
    opacity: 0;
  }

  [type="checkbox"] + label {
    @include themify($themes) {
      color: themed("drawer-tabs");
    }
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    line-height: 25px;
  }

  [type="checkbox"] + label::before {
    @include themify($themes) {
      border-color: themed("sidebar-color");
      background: themed("white-color");
    }
    content: "";
    position: absolute;
    right: -2.5rem;
    top: 2px;
    width: 23px;
    height: 22px;
    border: 1.4px solid;
    border-radius: 4px;
  }

  [type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    right: -2.8rem;
    top: 7px;
    width: 23px;
    height: 22px;
    background-image: url("../assets/img/tick.png");
    background-size: 14px;
    background-repeat: no-repeat;
    transform: scale(1);
  }
}
.toast-container {
  @include themify($themes) {
    box-shadow: 0px 5px 8px themed("box-shadow-color");
  }
  border-radius: 6px;
  .toast-title {
    @include themify($themes) {
      color: themed("drawer-tabs");
    }
    font-size: 14px;
  }
  .toast-body {
    @include themify($themes) {
      color: themed("toast-body");
    }
    font-size: 12px;
  }
  &.success {
    @include themify($themes) {
      border-left-color: themed("bar-color-13");
    }
    border-left: 6px solid;
  }
  &.failure {
    @include themify($themes) {
      border-left-color: themed("bar-color-9");
    }
    border-left: 6px solid;
  }
}

.color-black {
  color: black !important;
}

.feedback-heading {
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font: normal normal normal 20px;
  margin-left: 2rem !important;
}

.w-40 {
  width: 40%;
}

.csr-bottom-line {
  @include themify($themes) {
    border-top-color: themed("serv-req-border-color");
  }
  border-top: 1.5px dotted;
}

.helvetica-regular {
  @include themify($fontThemes) {
    font-family: themed("font-family-regular");
  }
}

.helv-13 {
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font: 13px;
}

.itsm-hr {
  border-bottom: 1px dotted !important;
}

.ml--3 {
  margin-left: -3rem !important;
}

.noti-width {
  width: 700px;
  max-width: 50%;
}

.noti-font {
  font-size: 16px;
}

.noti-arrow-icon {
  width: 25px;
  &.noti-arrow-icon-active {
    transform: rotate(180deg);
  }
}

.noti-card {
  @include themify($themes) {
    background-color: themed("body-bg");
  }
  border-radius: 8px;
  display: flex;
  .noti-strip {
    @include themify($themes) {
      background-color: themed("non-active-tabs");
    }
    width: 6px;
    border-radius: 8px;
    &.noti-strip-active {
      @include themify($themes) {
        background-color: themed("bar-color-1");
      }
    }
  }
  .noti-cord-content {
    margin: 22px 22px 22px 16px;
  }
  .noti-tick-icon {
    width: 16px;
  }
  .noti-time {
    @include themify($themes) {
      color: themed("incident-secondary-text");
    }
    font-size: 12px;
  }
  .btn-outline-primary {
    @include themify($themes) {
      background: themed("non-active-tabs");
      color: themed("serv-req-btn-text");
    }
    min-width: 80px;
    border: none;
    font-size: 12px;
  }
  .btn-primary {
    @include themify($themes) {
      border-color: themed("bar-color-1");
      background: themed("bar-color-1");
    }
    min-width: 80px;
    color: white;
    margin-left: 25px;
    font-size: 12px;
  }
}

.notification-close {
  margin: 0.5rem;
  height: 1rem;
}

.notification-badge {
  border-radius: 1rem;
  background-color: red;
  height: 1.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  top: -14px;
  font-size: smaller;
  right: -14px;
  color: white;
  min-width: 1.5rem;
  justify-content: center;
}

.badge-right-padding {
  padding-right: 0.05rem;
}

.width-200 {
  width: 200px;
}

.white-space-break {
  white-space: break-spaces;
}

.project-sidebar-btn {
  @include themify($themes) {
    color: themed("white-color") !important;
    box-shadow: 0px 3px 6px themed("sidebar-color") !important;
    background: themed("project-dd-background") 0% 0% no-repeat padding-box !important;
  }
  @include themify($fontThemes) {
    font-family: themed("font-family-bold");
  }
  opacity: 1;
  margin-bottom: 1rem;
  font: 12px;
  letter-spacing: 0px;
  width: 97%;
  height: 2.5rem;
  border-radius: 0rem 0.4rem 0.4rem 0rem;
}

.project-dd-sidebar-close {
  width: 95%;
  // border-radius: 0.2rem;
  flex-direction: column;
  height: 2.4rem;
}

.dd-arrow-style {
  @include themify($themes) {
    background: themed("white-color") 0% 0% no-repeat padding-box;
    background-color: themed("project-dd-arrow");
  }
  opacity: 1;
  filter: invert(1);
  width: 1.5rem;
}

.project-dropdown-text {
  @include themify($themes) {
    color: themed("project-dd-text") !important;
  }
}

.top-0 {
  top: 0;
}

.profile-dd-menu {
  @include themify($themes) {
    background-color: themed("sidebar-color") !important;
  }
  @include themify($fontThemes) {
    font: 12px themed("font-family");
  }
  top: -1rem !important;
  left: -0.5rem !important;
}

.profile-dd-menu a:hover {
  background-color: transparent;
  font-weight: bold;
}

.profile-dd-menu-name {
  left: 0.2rem !important;
}

.profile-dd-item {
  @include themify($themes) {
    color: themed("white-color") !important;
    &.selected-item {
      background-color: themed("sidebar-active-item") !important;
      color: themed("sidebar-color") !important;
    }
  }
  font-size: 0.75rem;
}

.profile-dd-logout {
  @include themify($themes) {
    color: themed("logout-color") !important;
  }
}

.profile-icons {
  height: 1.3rem;
}

.theme-dd-menu {
  @include themify($themes) {
    background-color: themed("sidebar-color") !important;
  }
  @include themify($fontThemes) {
    font-family: themed("font-family");
  }
  font-size: 0.75rem;
  left: 2.5rem !important;
}

.theme-dd-menu a:hover {
  background-color: transparent;
  font-weight: bold;
}

/*
Currency Modal styles
*/

.modal-container {
  @include themify($themes) {
    background-color: themed("white-color");
    box-shadow: 0px 3px 6px themed("page-header-box-shadow");
  }
  background: 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.currency-heading {
  @include themify($fontThemes) {
    font: 18px themed("font-family");
  }
  @include themify($themes) {
    color: themed("title-text");
  }
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.currency-icon {
  height: 12rem;
}

.currency-dd-button {
  @include themify($themes) {
    background-color: themed("white-color");
    color: themed("title-text") !important;
  }
  @include themify($fontThemes) {
    font: 19px themed("font-family-medium");
  }
  background: 0% 0% no-repeat padding-box;
  border: 1.3px solid #6e7164;
  border-radius: 6px;
  opacity: 1;
  height: 2rem;
  width: 8.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currency-val {
  @include themify($fontThemes) {
    font: 19px themed("font-family-medium");
  }
  @include themify($themes) {
    color: themed("title-text");
  }
  opacity: 1;
  margin-left: 5rem !important;
}

.select-currency-btn {
  @include themify($themes) {
    color: themed("white-color") !important;
    background-color: themed("service-request-button") !important;
  }
  background: 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  margin: 1rem;
  width: 9rem;
}

.cutom-btn {
  @include themify($themes) {
    background-color: themed("white-color") !important;
    border-color: themed("service-request-button");
    color: themed("service-request-button") !important;
  }
  background: 0% 0% no-repeat padding-box;
  border: 1px solid;
  border-radius: 6px;
  opacity: 1;
  width: 9rem;
  height: 2rem;
  margin: 1rem;
}

.currency-modal {
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 700px;
    }
  }
}

/*
Key Modal Styles
*/
.api-key-modal {
  .modal-dialog {
    max-width: 700px;
  }
  .api-key-min-height {
    min-height: 200px;
  }
  .status-icon {
    height: 200px;
    margin-bottom: -30px;
  }
  .key-status-text {
    @include themify($themes) {
      color: themed("title-text");
    }
  }
  .btn-primary {
    @include themify($themes) {
      background-color: themed("service-request-button");
      border-color: themed("service-request-button");
      color: themed("white-color");
    }
  }
  .api-key-input {
    min-width: 350px;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.width-inherit {
  width: inherit;
}

.accounts-data-container {
  height: 20rem;
  overflow: auto;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaac80;
    border-radius: 6px;
  }
}

.overflow-y-unset {
  overflow-y: unset;
}

.x-overflow-auto {
  overflow-x: auto !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.max-height-inherit {
  max-height: inherit !important;
}

/*
maintenance banner
*/

.mtnc-banner-container {
  @include themify($themes) {
    background-image: linear-gradient(
      272deg,
      themed("maintenance-banner-1"),
      themed("maintenance-banner-2")
    );
  }
  text-align: left;
  margin-top: -0.7rem;
  min-height: 3.2rem;
  @media screen and (max-width: 600px) {
    margin-top: 5rem;
  }
}

.service-msg {
  font-size: 13px;
  line-height: 22px;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  @include themify($themes) {
    color: themed("white-color");
  }
}

.time-btn.active {
  @include themify($themes) {
    background-color: themed("service-request-button");
    color: themed("white-color");
  }
}

.hide-scroll-bar {
  padding-right: 0.1rem;
  padding-left: 0.1rem;
  & ::-webkit-scrollbar {
    display: none;
  }
}

.flex-class {
  display: flex !important;
  flex: 1 1 auto !important;
}

.dropdown-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaac80;
    border-radius: 6px;
  }
}

.focus-outline {
  @include themify($themes) {
    outline: 2px solid themed("page-header-box-shadow");
    background-color: themed("service-request-button");
  }
}

.account-dropdown {
  .account-name {
    font-size: 14px;
    white-space: break-spaces;
  }
  .account-id {
    font-size: 12px;
  }
  .dropdown-container {
    border: none !important;
    height: 2.25rem;
    outline: none !important;
  }
  .dropdown-item:focus {
    @include themify($themes) {
      background-color: themed("service-request-button");
      color: themed("white-color");
    }
  }
  .select-item {
    padding: 0 1rem !important;
    margin-bottom: 0 !important;
    direction: rtl;
  }
  .dropdown-content {
    padding: 2px !important;
  }
  .panel-content {
    border-radius: 0.1rem !important;
  }
  .options {
    @extend .dropdown-scrollbar;
  }
  span {
    @extend .flex-class;
  }
  .btn:disabled {
    @extend .flex-class;
  }
}

.acc-apply {
  @include themify($themes) {
    color: themed("service-request-button");
  }
  font-weight: 600;
}

.acc-cancel {
  @include themify($themes) {
    color: themed("danger");
  }
  font-weight: 600;
}

.time-picker-scroll {
  @extend .dropdown-scrollbar;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  border-right: 0.1rem solid rgb(243, 241, 241);
}

@supports (-moz-appearance: none) {
  .time-picker-scroll {
    scrollbar-width: none;
  }
}

.drawer-search-input {
  border-right: none;
  & + .drawer-search-icon {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: calc(1.5em + 0.75rem + 2px);
    transition: border-color 0.15s ease-in-out;
  }
  &:focus {
    @include themify($themes) {
      border-width: 2px 0 2px 2px !important;
      border-color: themed("service-request-button") !important;
    }
    & + .drawer-search-icon {
      @include themify($themes) {
        border-width: 2px 2px 2px 0;
        border-color: themed("service-request-button") !important;
      }
    }
  }
}

.is-invalid {
  @include themify($themes) {
    border-color: themed("danger") !important;
  }
  &:focus {
    @include themify($themes) {
      box-shadow: 0 0 0 0.2rem themed("danger-shadow") !important;
    }
  }
}

.invalid-feedback {
  @include themify($themes) {
    color: themed("danger") !important;
  }
}

.max-width-200 {
  max-width: 200px;
}

.chip-container {
  display: flex;
  flex-flow: wrap;
  row-gap: 5px;
  column-gap: 10px;
}

.chip-style {
  @include themify($themes) {
    border: 1px solid themed("hr-border-color");
  }
  padding: 5px 15px;
  border-radius: 20px;
}

.rounded-right-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.flex-1 {
  flex: 1;
}

.mbl-pos {
  position: fixed;
  height: 60px;
  z-index: 1;
}

.header-color {
  @include themify($themes) {
    background-color: themed("sidebar-color");
  }
}

.c-sidebar-mbl {
  @include themify($themes) {
    background-color: themed("sidebar-color");
  }
  margin-top: 60px;
  width: 186px !important;
  transition: all 0.1s ease;
}

.leftn-186 {
  left: -186px !important;
}

.left-0 {
  left: 0;
}

@media screen and (max-width: 600px) {
  .project-select-dropdown-width {
    width: 48% !important;
  }
  .mobile-view-width-project {
    width: 200% !important;
  }
  .mobile-view-width-account {
    width: 200% !important;
  }
}

.scroll-disable {
  overflow: hidden;
}

.deloitte-logo-mobile {
  margin-left: calc(50vw - 30px);
}
.btn-removeFiles {
  border: 1px solid black;
}

.ml-checkbox {
  margin-left: -1.25rem;
}
.compliant-bg-color {
  @include themify($themes) {
    background-color: themed("compliant-color");
  }
}
.excepted-bg-color {
  @include themify($themes) {
    background-color: themed("exempted-color");
  }
}
.non-compliance-bg-color {
  @include themify($themes) {
    background-color: themed("non-compliance-color");
  }
}
.stopped-bg-color {
  @include themify($themes) {
    background-color: themed("stopped-color");
  }
}
.stopped-excepted-bg-color {
  @include themify($themes) {
    background-color: themed("stopped-exempted-color");
  }
}
.severity-critical-bg-color {
  @include themify($themes) {
    background-color: themed("severity-critical");
  }
}
.severity-high-bg-color {
  @include themify($themes) {
    background-color: themed("bar-color-14");
  }
}
.scan-never-performed-bg-color {
  @include themify($themes) {
    background-color: themed("scan-never-performed");
  }
}
.users-table {
  background-color: white;
}
.roles-table{
  width: 100%;
  overflow-x: auto;
}
.user-modal-height {
  height: 34rem;
}
.user-confirm {
  z-index: 1060;
}
.user-confirm + .modal-backdrop {
  z-index: 1050;
}
.security-buttons-style {
  background-color: white;
  @include themify($themes) {
    color: themed("title-text");
  }
  height: 2.188rem;
  min-width: 6.13rem;
  border-radius: 4px 4px 0px 0px;
  border: none;
}

.security-active-buttons-style {
  @include themify($themes) {
    background-color: themed("security-buttons-color");
  }
  @include themify($themes) {
    border-bottom-color: themed("security-button-shadow");
  }
  border-bottom: 4px solid;
}

#change-theme-dropdown {
  &:hover {
    background-color: transparent;

    p {
      font-weight: bold;
    }
  }
  &:focus {
    background-color: transparent;
  }
  button {
    &:focus {
      outline: none; // Remove the border (outline) on click (focus)
    }
  }
}

.modal-backdrop {
  height: 100%;
}
