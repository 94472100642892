.w-27 {
  width: 28%;
}

.dd-container {
  align-self: center;
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.dd-menu-css {
  backface-visibility: hidden;
}

.wrap-anywhre {
  overflow-wrap: anywhere;
}

.w-0 {
  width: 0 !important;
}
