// Here you can add other styles
@use "sass:map";

$body-bg: #f2f4f8;
$body-color: #111;
$theme-colors: (
  "deloitte-green": #95c623,
  "primary": #fff,
  "secondary": #95c623,
  "tertiary": #0075a2,
  "quaternary": #000000,
  "quinternary": #d55672,
  "primaryDarker": #ddd,
  "secondaryLighter": #a1e404,
  "secondaryDarker": #8db03b,
  "info": #95c623,
  "mono-green-0": hsl(89, 80%, 20%),
  "mono-green-1": hsl(89, 80%, 30%),
  "mono-green-2": hsl(89, 80%, 40%),
  "mono-green-3": hsl(89, 80%, 50%),
  "mono-green-4": hsl(89, 80%, 60%),
  "mono-green-5": hsl(89, 80%, 70%),
  "mono-green-6": hsl(89, 80%, 80%)
);

$themes: (
  nestle: (
    // "white-2": #ffffff00,
    // "light-orange-2": #ffb271,
    // "light-orange-3": #ffca9d,
    // "light-orange-4": #ffe6d1,
    // "dark-orange-3": #ff9134,
    // "light-sea-green": #9ae5ff,
    // "sea-green-2": #1c9bc7,
    // "sea-green-3": #3fbfea,
    // "light-blue-3": #a5ccff,
    // "dark-blue-3": #6da0e3,
    // "dark-blue-5": #4a5164,
    // "light-grey-4": #1522322e,
    // "light-grey-6": #4e4e4e,
    // "light-grey-7": #707070,
    // "light-grey-8": #adadad,
    // "light-grey-10": #c7c7c7,
    // "light-grey-11": #8d8d8d,
    // "light-grey-12": #959595,
    // "light-grey-13": #0000000a,
    // "light-grey-15": #e2e2e2,
    // "light-grey-16": #d7d6d6,
    // "dark-grey-2": #454a3a,
    // "dark-grey-3": #494e53,
    // "dark-grey-4": #485465,
    // "dark-grey-6": #3b3b3b,
    // "dark-grey-8": #959b87,
    // "black-3": #131523,
    // "black-4": #000000cc,
    // "black-5": #4d4d4d,
    // "black-6": #27313c,
    // "black-7": #3a3e35,
    // "black-8": #1a1616,
    // "black-9": #1a1818,
    // 'dark-blue-6': #050505,
    "feedback-admin-border": #70707042,
    "feedback-header-text": #6e7164,
    "urgency-low-color": #1b9cc8,
    "urgency-low-bg": #1b9cc84d,
    "urgency-med-bg": #fa8a2a4a,
    "urgency-high-bg": #ff505954,
    "black-color": #0b0b0b,
    "last-sorted-bg": #d3d3d3c4,
    "exempted-color": #ff5059,
    "non-compliance-color": #656b8d,
    "acc-danger-color": #e35b64,
    "sidebar-active-item": #ffffff,
    "compliant-color": #fa8a2a,
    "non-active-tabs": #d0daf3,
    "drawer-table-header": #e8efff,
    "drawer-tabs": #343955,
    "key-contacts-text": #11193c,
    "body-bg": #f2f4f8,
    "box-shadow-color": #15223214,
    "page-header-box-shadow": #00000029,
    "hr-border-color": #aaaaaa,
    "date-range-bottom-border": #e9e9f0,
    "ticket-summary-text": #333333,
    "incident-secondary-text": #606060,
    "calendar-icon-color": #6e7164,
    "title-text": #222838,
    "primary-text-color": #000000,
    "open-light-bar-color": #ffab64,
    "bar-color-1": #1b9cc8,
    "bar-color-2": #f4a261,
    "bar-color-3": #8fd372,
    "bar-color-4": #ffab64,
    "bar-color-5": #a9e68e,
    "bar-color-7": #d1e674,
    "bar-color-8": #72e7f6,
    "bar-color-9": #e86f51,
    "bar-color-10": #ffca45,
    "bar-color-11": #9d566e,
    "bar-color-12": #507dc6,
    "bar-color-13": #39c3c3,
    "bar-color-14": #ff5159,
    "bar-color-15": #ff9e1a,
    "bar-color-16": #cbd6f2,
    "bar-color-default": #e5e5e5,
    "sidebar-color": #405898,
    "welcome-banner-color": #405898,
    "greyed-out": #9aa0a8,
    "resolved-color": #0fc39a,
    "resolved-background": #0fc39a54,
    "resolved-border": #d9ef79,
    "pagination-hover-not-active": #ddd,
    "toast-body": #a0a3b1,
    "serv-req-border-color": #d8dbe0,
    "tooltip-border-color": #555,
    "calendar-title-color": #565d42,
    "serv-req-btn-text": #485f9d,
    "table-border-color": #ededed,
    "table-bg-color": #fafafa,
    "table-border-color-secondary": #22543d,
    "code-&-link-color": #3c4b64,
    "default-link-hover-color": #2391c6c2,
    "seperator-color": #92d400,
    "okta-sign-in-color": #86bc25,
    "okta-sign-in-hover": #92c23a,
    "okta-form-input": #0d1203,
    "checkbox-disabled-color": #80808054,
    "code-background": #eee,
    "white-color": white,
    "service-request-button": #1b9cc8,
    "cancel-btn-bg": #d0daf3,
    "welcome-banner-text": white,
    "project-dd-text": black,
    "logout-color": #ff5059,
    "timeline-bar-color": #bfbfbf,
    "modal-fields-background": #e8e8e8,
    "danger": red,
    "maintenance-banner-1": #ff5059,
    "maintenance-banner-2": #f2388e,
    "urgency-med-color": #fa8a2a,
    "urgency-high-color": #656b8d,
    "tr-hover": #ececec
  ),
  deloitte: (
    // Theme Used Color
    "bar-color-1": #1b9cc8,
    "bar-color-2": #8fd372,
    "bar-color-3": #f4a261,
    "bar-color-4": #ffab64,
    "bar-color-5": #8fd372,
    "bar-color-7": #1b9cc8,
    "bar-color-8": #a9e68e,
    "bar-color-9": #e86f51,
    "bar-color-10": #ffca45,
    "bar-color-11": #9d566e,
    "bar-color-12": #507dc6,
    "bar-color-13": #39c3c3,
    "bar-color-14": #ff5159,
    "bar-color-15": #ff9e1a,
    "bar-color-16": #cbd6f2,
    // Other Theme Common Color
    "bar-color-default": #e5e5e5,
    "open-light-bar-color": #ffab64,
    "body-bg": #f6faf1,
    "sidebar-color": #1e1e2d,
    "greyed-out": #9aa0a8,
    "compliant-color": #8fd372,
    "exempted-color": #fa8a2a,
    "non-compliance-color": #ff5059,
    "page-header-box-shadow": #00000029,
    "service-request-button": #0fc39a,
    "cancel-btn-bg": #ffff,
    // Other Color
    // Not Found Color ["serv-req-border-color"]
    "feedback-admin-border": #70707042,
    "feedback-header-text": #6e7164,
    "urgency-low-color": #1b9cc8,
    "urgency-low-bg": #1b9cc84d,
    "urgency-med-bg": #fa8a2a4a,
    "urgency-high-bg": #ff505954,
    "black-color": #0b0b0b,
    "last-sorted-bg": #d3d3d3c4,
    "sea-green-1": #8db03b,
    "sidebar-active-item": #d9ef79,
    "welcome-banner-color": #d9ef79,
    "non-active-tabs": #e6efdc,
    "drawer-table-header": #f6faf1,
    "drawer-tabs": #1e1e2d,
    "table-border-color": #ededed,
    "table-bg-color": #fafafa,
    "table-border-color-secondary": #22543d,
    "tooltip-border-color": #555,
    "code-&-link-color": #3c4b64,
    "default-link-hover-color": #95c623,
    "seperator-color": #92d400,
    "okta-sign-in-color": #86bc25,
    "okta-sign-in-hover": #92c23a,
    "okta-form-input": #0d1203,
    "checkbox-disabled-color": #80808054,
    "code-background": #eee,
    "box-shadow-color": #15223214,
    "calendar-icon-color": #6e7164,
    "pagination-hover-not-active": #ddd,
    "acc-danger-color": #e35b64,
    "primary-text-color": #000000,
    "white-color": white,
    "project-dd-arrow": #a7a7a7,
    "project-dd-background": #585858,
    "welcome-banner-text": black,
    "project-dd-text": black,
    "logout-color": #ff5059,
    "title-text": #222838,
    "calendar-title-color": #565d42,
    "resolved-color": #0fc39a,
    "timeline-bar-color": #bfbfbf,
    "modal-fields-background": #e8e8e8,
    "danger": red,
    "maintenance-banner-1": #ff5059,
    "maintenance-banner-2": #f2388e,
    "urgency-med-color": #8fd372,
    "urgency-high-color": #656b8d,
    "danger-shadow": rgba(218, 41, 28, 0.25),
    "tr-hover": #ececec,
    "stopped-color": #6ec1d9,
    "stopped-exempted-color": #656b8d,
    "scan-never-performed": #da291c,
    "security-buttons-color":#F0F0F0,
    "severity-critical":#CB3C42,
    "security-button-shadow":#AAAAAA
  ),
  protanopia: (
    // Theme Used Color
    "bar-color-1": #bdd9c0,
    "bar-color-3": #cbbf03,
    "bar-color-4": #a998df,
    "bar-color-5": #d4c06c,
    "bar-color-7": #bdd9c0,
    "bar-color-8": #fec957,
    "bar-color-9": #a998df,
    "bar-color-10": #ead049,
    "bar-color-11": #696b79,
    "bar-color-12": #5f7ac3,
    "bar-color-13": #b0aeb7,
    "bar-color-14": #988e6f,
    "bar-color-15": #cab325,
    "bar-color-16": #d0d3f1,
    "bar-color-17": #a59b80,
    // Other Theme Common Color
    "bar-color-default": #e8e3e4,
    "body-bg": #fff7ef,
    "sidebar-color": #212431,
    "greyed-out": #9aa0a8,
    "compliant-color": #cbbf03,
    "exempted-color": #788bcb,
    "non-compliance-color": #0251fe,
    "page-header-box-shadow": #afa6a6,
    "service-request-button": #636d97,
    "cancel-btn-bg": #000000,
    // other Color
    "previous-month": #8fd372,
    "current-month": #f4a261,
    "predicted-spend": #1b9cc8,
    "background-color": #fff7ef,
    "open-bar-color": #a998df,
    "open-light-bar-color": #a998df,
    "resolved-bar-color": #bdd9c0,
    "auto-resolved-bar-color": #fec957,
    "key-low-color": #788bcb,
    "key-moderate-color": #cbbf03,
    "key-high-color": #6d6416,
    "key-critical-color": #3e414b,
    "bar-color-2": #788bcb,
    "sidebar-active-item": #fce475,
    "close-1": #bdd9c0,
    "close-2": #fec957,
    "close-3": #a998df,
    "close-4": #929084,
    "close-5": #e5323b,
    "danger": #b6aa31,
    "greyed-out-color": #9e9da6,
    "scan-never-performed": #040f45,
    "open-1": #e1daae,
    "open-2": #848fa1,
    "open-3": #038fdc,
    "open-4": #ff9450,
    "open-5": #cc2c34,
    "stopped-color": #6d6416,
    "stopped-exempted-color": #3e414b,
    "last-sorted-bg": #d3d3d3c4,
    "white-color": #ffffff,
    "drawer-tabs": #212431,
    "code-&-link-color": #3c4b64,
    "tabs-border": #000000,
    "star-filled": #f6d908,
    "welcome-banner-color": #fce475,
    "box-shadow-color": #15223214,
    "primary-text-color": #000000,
    "urgency-high-color": #383631,
    "urgency-high-bg": #c9bd9e,
    "urgency-med-color": #414141,
    "urgency-med-bg": #bebcc5,
    "urgency-low-color": #575350,
    "urgency-low-bg": #e2dad1,
    "logout-color": #988e6f,
    "danger-shadow": rgba(182, 170, 49, 0.25),
    "tr-hover": #ececec,
    "security-buttons-color":#F0F0F0,
    "severity-critical":#CB3C42,
    "security-button-shadow":#AAAAAA,
  ),
  deuteranopia: (
    // Theme Used Color
    "bar-color-1": #e1daae,
    "bar-color-2": #506266,
    "bar-color-3": #d5d58d,
    "bar-color-4": #058fda,
    "bar-color-5": #eab77a,
    "bar-color-7": #e1daae,
    "bar-color-8": #2e3142,
    "bar-color-9": #058fda,
    "bar-color-10": #ffc76c,
    "bar-color-11": #776869,
    "bar-color-12": #507cc5,
    "bar-color-13": #b4abca,
    "bar-color-14": #b0884e,
    "bar-color-15": #e4a915,
    "bar-color-16": #dbcff3,
    "bar-color-17": #bc9465,
    // Other Theme Color
    "bar-color-default": #fadde7,
    "body-bg": #fff7ef,
    "sidebar-color": #212431,
    "greyed-out": #9aa0a8,
    "compliant-color": #dbf226,
    "exempted-color": #f3e3d4,
    "non-compliance-color": #d5d58d,
    "page-header-box-shadow": #afa6a6,
    "service-request-button": #5d6e9e,
    "cancel-btn-bg": #000000,
    // Other Colors
    "previous-month": #8fd372,
    "current-month": #f4a261,
    "predicted-spend": #1b9cc8,
    "background-color": #fff6f7,
    "open-bar-color": #058fda,
    "open-light-bar-color": #058fda,
    "resolved-bar-color": #e1daae,
    "auto-resolved-bar-color": #2e3142,
    "key-low-color": #9fc031,
    "key-moderate-color": #d5d58d,
    "key-high-color": #13678b,
    "key-critical-color": #506266,
    "close-1": #bdd9c0,
    "close-2": #fec957,
    "close-3": #a998df,
    "close-4": #929084,
    "close-5": #e5323b,
    "danger": #c6b300,
    "greyed-out-color": #a999a8,
    "scan-never-performed": #506266,
    "open-1": #e1daae,
    "open-2": #ff9450,
    "open-3": #848fa1,
    "open-4": #038fdc,
    "open-5": #cc2c34,
    "stopped-color": #9fc031,
    "stopped-exempted-color": #0a3f55,
    "sidebar-active-item": #ffdebb,
    "last-sorted-bg": #d3d3d3c4,
    "white-color": #ffffff,
    "drawer-tabs": #212431,
    "code-&-link-color": #3c4b64,
    "tabs-border": #000000,
    "star-filled": #ffd28f,
    "welcome-banner-color": #ffdebb,
    "box-shadow-color": #15223214,
    "primary-text-color": #000000,
    "urgency-high-color": #473c48,
    "urgency-high-bg": #cab8cb,
    "urgency-med-color": #433831,
    "urgency-med-bg": #dcb69f,
    "urgency-low-color": #5a4747,
    "urgency-low-bg": #f4d4d4,
    "logout-color": #b0884e,
    "danger-shadow": rgba(198, 179, 0, 0.25),
    "tr-hover": #ececec,
    "security-buttons-color":#F0F0F0,
    "severity-critical":#CB3C42,
    "security-button-shadow":#AAAAAA
  ),
  tritanopia: (
    // Theme Used Color
    "bar-color-1": #a692ae,
    "bar-color-2": #5cd0f5,
    "bar-color-3": #fb6b93,
    "bar-color-4": #235fa5,
    "bar-color-5": #a1c6d6,
    "bar-color-7": #a692ae,
    "bar-color-8": #6ede70,
    "bar-color-9": #235fa5,
    "bar-color-10": #ffc0cb,
    "bar-color-11": #9a5960,
    "bar-color-12": #368792,
    "bar-color-13": #45c0cf,
    "bar-color-14": #ff5257,
    "bar-color-15": #ff959e,
    "bar-color-16": #c8d7e8,
    "bar-color-17": #ff6d74,
    // Other Theme Color
    "bar-color-default": #e7e2f3,
    "body-bg": #f9f7ff,
    "sidebar-color": #212528,
    "greyed-out": #9aa0a8,
    "compliant-color": #b1daf1,
    "exempted-color": #faaab8,
    "non-compliance-color": #fb6b93,
    "page-header-box-shadow": #afa6a6,
    "service-request-button": #13a4c5,
    "cancel-btn-bg": #000000,
    // Other Colors
    "background-color": #f9f7ff,
    "open-bar-color": #235fa5,
    "open-light-bar-color": #235fa5,
    "resolved-bar-color": #a692ae,
    "auto-resolved-bar-color": #6ede70,
    "key-low-color": #faaab8,
    "key-moderate-color": #5cd0f5,
    "key-high-color": #13a4c5,
    "key-critical-color": #65829f,
    "close-1": #bdd9c0,
    "close-2": #fec957,
    "close-3": #a998df,
    "close-4": #929084,
    "close-5": #e5323b,
    "danger": #fb6a92,
    "greyed-out-color": #999eaa,
    "scan-never-performed": #65829f,
    "open-1": #e1daae,
    "open-2": #848fa1,
    "open-3": #038fdc,
    "open-4": #ff9450,
    "open-5": #cc2c34,
    "stopped-color": #5cd0f5,
    "stopped-exempted-color": #13a4c5,
    "sidebar-active-item": #e9e1f2,
    "last-sorted-bg": #d3d3d3c4,
    "white-color": #ffffff,
    "drawer-tabs": #212528,
    "welcome-banner-color": #e9e1f2,
    "code-&-link-color": #3c4b64,
    "tabs-border": #000000,
    "star-filled": #ffccd6,
    "box-shadow-color": #15223214,
    "primary-text-color": #000000,
    "urgency-high-color": #fb2869,
    "urgency-high-bg": #fdcddc,
    "urgency-med-color": #65829f,
    "urgency-med-bg": #cfe3f7,
    "urgency-low-color": #13a4c5,
    "urgency-low-bg": #d3f4fc,
    "logout-color": #ff5256,
    "danger-shadow": rgba(251, 106, 146, 0.25),
    "tr-hover": #ececec,
    "security-buttons-color":#F0F0F0,
    "severity-critical":#CB3C42,
    "security-button-shadow":#AAAAAA
  ),
  achromatopsia: (
    // Theme Used Color
    "bar-color-1": #bebebe,
    "bar-color-2": #484848,
    "bar-color-3": #cbcbcb,
    "bar-color-4": #656565,
    "bar-color-5": #bebebe,
    "bar-color-7": #bebebe,
    "bar-color-8": #363535,
    "bar-color-9": #656565,
    "bar-color-10": #cccccc,
    "bar-color-11": #676767,
    "bar-color-12": #797979,
    "bar-color-13": #a6a6a6,
    "bar-color-14": #777777,
    "bar-color-15": #a9a9a9,
    "bar-color-16": #d6d6d6,
    "bar-color-17": #8d8d8d,
    // Other Theme Color
    "bar-color-default": #e5e5e5,
    "body-bg": #f8f8f8,
    "sidebar-color": #1f1f1f,
    "greyed-out": #9aa0a8,
    "compliant-color": #d6d6d6,
    "exempted-color": #b9b9b9,
    "non-compliance-color": #9b9b9b,
    "page-header-box-shadow": #afa6a6,
    "service-request-button": #2e2d2d,
    "cancel-btn-bg": #000000,
    // Other Colors
    "background-color": #f8f8f8,
    "open-bar-color": #656565,
    "open-light-bar-color": #656565,
    "resolved-bar-color": #bebebe,
    "auto-resolved-bar-color": #363535,
    "key-low-color": #b9b9b9,
    "key-moderate-color": #9b9b9b,
    "key-high-color": #818181,
    "key-critical-color": #484848,
    "close-1": #dedede,
    "close-2": #b1b1b1,
    "close-3": #969696,
    "close-4": #646464,
    "close-5": #070707,
    "danger": #808080,
    "greyed-out-color": #9f9f9f,
    "scan-never-performed": #1c1c1c,
    "open-1": #070707,
    "open-2": #646464,
    "open-3": #969696,
    "open-4": #b1b1b1,
    "open-5": #dedede,
    "stopped-color": #818181,
    "stopped-exempted-color": #484848,
    "sidebar-active-item": #f2f4f8,
    "last-sorted-bg": #d3d3d3c4,
    "white-color": #ffffff,
    "drawer-tabs": #1f1f1f,
    "welcome-banner-color": #e2e2e2,
    "code-&-link-color": #3c4b64,
    "tabs-border": #000000,
    "star-filled": #d0d0d0,
    "box-shadow-color": #15223214,
    "primary-text-color": #000000,
    "urgency-high-color": #1c1c1c,
    "urgency-high-bg": #d9d5d5,
    "urgency-med-color": #484848,
    "urgency-med-bg": #e3dddd,
    "urgency-low-color": #818181,
    "urgency-low-bg": #ebebeb,
    "logout-color": #767676,
    "danger-shadow": rgba(128, 128, 128, 0.25),
    "tr-hover": #ececec,
    "security-buttons-color":#F0F0F0,
    "severity-critical":#CB3C42,
    "security-button-shadow":#AAAAAA
  )
);
