@import "src/styles/colors";
@import "src/styles/mixins";

.date-range-filter {
  border: none;
  .react-calendar__month-view__weekdays {
    @include themify($themes) {
      border-bottom-color: themed('date-range-bottom-border'); 
    }
    border-bottom: 1px solid;
  }
  abbr[title] {
    text-decoration: none;
  }
  .react-calendar__tile--active {
    @include themify($themes) {
      background: themed('drawer-table-header'); 
      color: themed('calendar-title-color'); 
    }
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    @include themify($themes) {
      background: themed('service-request-button'); 
    }
  }
  .react-calendar__tile--rangeStart {
    @include themify($themes) {
      background: themed('service-request-button'); 
    }
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .react-calendar__tile--rangeEnd {
    @include themify($themes) {
      background: themed('service-request-button'); 
    }
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .react-calendar__tile--hasActive {
    @include themify($themes) {
      background: themed('service-request-button'); 
    }
  }
}
  